<template>
  <PageWrapper>
    <PagePanel class="register_panel">
      <div class="progress_title">
        <p v-show="showPage.id == 1 || showPage.id == 2">{{
          $t('register.tabs.personalDetails') }}</p>
        <p v-show="showPage.id == 3">{{ $t('register.tabs.moreAboutYou') }}</p>
        <p v-show="showPage.id == 4">{{ $t('register.tabs.accountConfig') }}</p>
        <p v-show="showPage.id == 5">
          {{ $t('register.tabs.confirmYourId') }}
          <svg-icon className="help_icon" slot="reference" iconClass="help" @click="openConfirmIdDrawer"></svg-icon>
        </p>
        <p v-show="showPage.id == 6">{{ $t('register.btn.done') }}</p>
      </div>
      <Progress :userProgress="userProgress"></Progress>
      <div class="register_form">
        <div class="main">
          <p class="title">{{ showPage.name }}</p>
          <component ref="page" :is="showPage.component" :showPageNumber="showPageNumber" @addPage="addPage"
            @skipNextStep="skipNextStep"></component>
        </div>
      </div>
    </PagePanel>
    <template slot="fixedFooter" v-if="showPageNumber < 6">
      <div class=" register_footer">
        <el-button v-if="showPageNumber > 1" class="btn-default" @click="reducePage" data-testid="back">
          {{ this.$t('register.btn.back') }}
        </el-button>
        <LoadingButton :text="$t('register.btn.next')" :callback="submit" data-testid="next"></LoadingButton>
      </div>
    </template>
    <confirmIdInfo ref="confirmIdInfo"></confirmIdInfo>
  </PageWrapper>
</template>

<script>
import PersonalDetails from '@/components/register/PersonalDetails';
import MoreAboutYou from '@/components/register/MoreAboutYou';
import AccountConfiguration from '@/components/register/AccountConfiguration';
import ConfirmID from '@/components/register/ConfirmID';
import Progress from '@/components/register/Progress';
import LoadingButton from '@/components/LoadingButton';
import registerMixins from '@/mixins/register';
import confirmIdInfo from '../mainTemplate/ConfirmIdInfo.vue'
import RegisterFinish from '@/views/register/RegisterFinish.vue'

export default {
  data() {
    return {
      progressList: [
        {
          name: this.$t('register.tabs.personalDetails'),
          id: 1,
          progress: 25,
          minutes: 5,
          component: 'PersonalDetails'
        },
        {
          name: this.$t('register.personalDetails.page2.pageTitle'),
          id: 2,
          progress: 35,
          minutes: 4,
          component: 'PersonalDetails'
        },
        {
          name: this.$t('register.moreAboutYou.pageTitle1'),
          id: 3,
          progress: 55,
          minutes: 3,
          component: 'MoreAboutYou'
        },
        {
          name: this.$t('register.tabs.accountConfig'),
          id: 4,
          progress: 80,
          minutes: 2,
          component: 'AccountConfiguration'
        },
        {
          name: this.$t('register.tabs.confirmYourId'),
          id: 5,
          progress: 90,
          minutes: 1,
          component: 'ConfirmID'
        },
        {
          id: 6,
          progress: 100,
          // name: this.$t('register.tabs.fundYourAcc'),
          component: 'RegisterFinish'
        }
      ]
    };
  },
  mixins: [registerMixins],
  components: {
    PersonalDetails,
    MoreAboutYou,
    AccountConfiguration,
    ConfirmID,
    Progress,
    LoadingButton,
    confirmIdInfo,
    RegisterFinish
  },
  provide() {
    return {
      onIdPlayerReady: this.onIdPlayerReady,
      onPoaPlayerReady: this.onPoaPlayerReady
    };
  }
};
</script>

<style lang="scss">
// @import '@/assets/css/pages/register/register.scss';
.register_panel {
  margin-bottom: 72px;

  .progress_title {

    p {
      font-size: 16px;
      color: #09090D;
      font-weight: 700;
      line-height: 24px;

      .svg-icon {
        color: $primary;
        @include rtl-sass-prop(margin-left, margin-right, 4px);
        cursor: pointer;
      }
    }
  }

  .register_form {

    .title {
      font-size: 14px;
      color: $text-primary;
      line-height: 22px;
      font-weight: 500;
      margin-bottom: 24px;
    }

    .el-select {
      width: 100%;
    }
  }
}

.register_footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: $white;
  box-shadow: 0px -4px 10px rgba(14, 18, 54, 0.1);
  padding: 16px 48px;

  @include screen-mobile {
    padding: 16px 24px;
  }
}
</style>
