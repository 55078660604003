<template>
  <SDrawer :title="$t('register.tabs.confirmYourId')" :visible.sync="visible" @confirm="handleConfirm"
    :showFooterCancel="false" custom-class="confirm_id_info_drawer">
    <StatusAlert :title="$t('register.confirmYourId.note')" type="warning" show-icon :closable="false">
    </StatusAlert>
    <div class="info_box">
      <p class="title">{{ $t('register.confirmYourId.poidTitle') }}</p>
      <p class="tip">{{ $t('register.confirmYourId.uploadDescription') }}</p>
      <div class="info_content">
        <img src="@/assets/images-1/id.png" alt="">
        <ul>
          <li>{{ $t('register.confirmYourId.poidTypes.photoId') }}</li>
          <li>{{ $t('register.confirmYourId.poidTypes.passport') }}</li>
          <li>{{ $t('register.confirmYourId.poidTypes.drivingLicence') }}</li>
        </ul>
      </div>
    </div>
    <div class="info_box">
      <p class="title">{{ $t('register.confirmYourId.porTitle') }}</p>
      <p class="tip">{{ $t('register.confirmYourId.uploadDescription') }}</p>
      <div class="info_content">
        <img src="@/assets/images-1/poa.png" alt="">
        <ul v-html="$t('register.confirmYourId.porTypes')"></ul>
      </div>
    </div>
  </SDrawer>
</template>

<script>
import StatusAlert from '@/components/customUI/StatusAlert.vue'

export default {
  components: {
    StatusAlert
  },
  data() {
    return {
      visible: false,
    }
  },
  methods: {
    openDrawder() {
      this.visible = true;
    },
    handleConfirm() {
      this.visible = false;
    }
  }

};
</script>

<style lang="scss" scoped>
.title {
  margin: 24px 0 8px;
  font-size: 14px;
  color: $text-secondary;
  line-height: 22px;
}

.tip {
  margin-bottom: 16px;
  font-size: 12px;
  color: $text-secondary;
  line-height: 18px;
}

img {
  margin-bottom: 8px;
}

.info_content {

  /deep/ ul {
    li {
      font-size: 14px;
      color: $text-primary;
      line-height: 22px;
    }
  }
}
</style>
