<template>
  <div class="progress-wrap">
    <div class="progress-main">
      <div class="progress-bar" :style="barWidth"> </div>
      <div class="progress-pre" :style="preLeft">{{ userPercentage }}%</div>
      <div class="progress-logo rotate" :style="rotateLeft">
        <svg-icon iconClass="logo_star"></svg-icon>
        <div class="top"></div>
        <div class="bottom"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: { userProgress: Object },
  data() {
    return {
      myInterval: null,
      userPercentage: 0
    };
  },
  watch: {
    registerStep: {
      handler(number) {
        this.$nextTick(() => {
          this.myInterval = setInterval(
            function () {
              if (this.userPercentage >= this.userProgress.progress) clearInterval(this.myInterval);
              else this.userPercentage++;
            }.bind(this),
            50
          );
        });
      },
      immediate: true
    }
  },
  computed: {
    registerStep() {
      return this.$store.state.common.registerStep;
    },
    barWidth() {
      return {
        '--width': `${this.userPercentage}%`
      }
    },
    preLeft() {
      return this.userPercentage < 25 ? {
        '--left': `calc(${this.userPercentage + '%'})`
      } : {
        '--left': `calc(${this.userPercentage + '%'} - 34px)`
      }
    },
    rotateLeft() {
      return this.userPercentage < 25 ? {
        '--left': `calc(${this.userPercentage + '%'} + 34px)`
      } : {
        '--left': `calc(${this.userPercentage + '%'})`
      }
    }
  }
};
</script>

<style lang="scss" scoped>
// @import '@/assets/css/components/register/progress.scss';
.progress-wrap {
  margin: 16px 0;

  .progress-main {
    width: 100%;
    position: relative;
    height: 4px;
    background: #e8edf4;
    border-radius: 2px;

    .progress-bar {
      height: 4px;
      width: var(--width);
      border-radius: 2px;
      background-color: $primary;
      transition: all 0.8s;
    }

    .progress-pre {
      position: absolute;
      top: -8px;
      width: 34px;
      height: 20px;
      line-height: 20px;
      background-color: $primary;
      border-radius: 10px;
      transition: all 0.8s;
      color: $white;
      text-align: center;
      font-size: 12px;
      @include rtl-sass-prop(left, right, var(--left));
    }

    .progress-logo {
      position: absolute;
      top: -5px;
      line-height: 1;
      transition: all 0.8s;
      @include rtl-sass-prop(margin-left, margin-right, 2px);
      @include rtl-sass-prop(left, right, var(--left));

      .top,
      .bottom {
        position: absolute;
        width: 2.5px;
        height: 2.5px;
        background-color: $primary;
      }

      .top {
        @include rtl-sass-prop-dual(left, 6px, right, 6px);
        top: -2px;
      }

      .bottom {
        @include rtl-sass-prop-dual(left, 6px, right, 6px);
        bottom: -2px;
      }
    }

    .rotate {
      animation-duration: 0.8s;
      animation-name: starRotate;

      .top {
        animation-name: starTop;
        animation-duration: 0.8s;
      }

      .bottom {
        animation-name: starBottom;
        animation-duration: 0.8s;
      }
    }

    @-webkit-keyframes starRotate {
      0% {
        -webkit-transform: rotate(0deg);
      }

      10% {
        -webkit-transform: rotate(36deg);
      }

      20% {
        -webkit-transform: rotate(72deg);
      }

      30% {
        -webkit-transform: rotate(108deg);
      }

      40% {
        -webkit-transform: rotate(144deg);
      }

      50% {
        -webkit-transform: rotate(180deg);
      }

      60% {
        -webkit-transform: rotate(216deg);
      }

      70% {
        -webkit-transform: rotate(252deg);
      }

      80% {
        -webkit-transform: rotate(288deg);
      }

      90% {
        -webkit-transform: rotate(324deg);
      }

      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes starRotate {
      0% {
        -webkit-transform: rotate(0deg);
      }

      10% {
        -webkit-transform: rotate(36deg);
      }

      20% {
        -webkit-transform: rotate(72deg);
      }

      30% {
        -webkit-transform: rotate(108deg);
      }

      40% {
        -webkit-transform: rotate(144deg);
      }

      50% {
        -webkit-transform: rotate(180deg);
      }

      60% {
        -webkit-transform: rotate(216deg);
      }

      70% {
        -webkit-transform: rotate(252deg);
      }

      80% {
        -webkit-transform: rotate(288deg);
      }

      90% {
        -webkit-transform: rotate(324deg);
      }

      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @-webkit-keyframes starTop {
      0% {
        top: -3px;
      }

      10% {
        top: -4px;
      }

      20% {
        top: -5px;
      }

      30% {
        top: -6px;
      }

      40% {
        top: -7px;
      }

      50% {
        top: -7px;
      }

      60% {
        top: -6px;
      }

      70% {
        top: -5px;
      }

      80% {
        top: -4px;
      }

      90% {
        top: -3px;
      }

      100% {
        top: -2px;
      }
    }

    @keyframes starTop {
      0% {
        top: -3px;
      }

      10% {
        top: -4px;
      }

      20% {
        top: -5px;
      }

      30% {
        top: -6px;
      }

      40% {
        top: -7px;
      }

      50% {
        top: -7px;
      }

      60% {
        top: -6px;
      }

      70% {
        top: -5px;
      }

      80% {
        top: -4px;
      }

      90% {
        top: -3px;
      }

      100% {
        top: -2px;
      }
    }

    @-webkit-keyframes starBottom {
      0% {
        bottom: -3px;
      }

      10% {
        bottom: -4px;
      }

      20% {
        bottom: -5px;
      }

      30% {
        bottom: -6px;
      }

      40% {
        bottom: -7px;
      }

      50% {
        bottom: -7px;
      }

      60% {
        bottom: -6px;
      }

      70% {
        bottom: -5px;
      }

      80% {
        bottom: -4px;
      }

      90% {
        bottom: -3px;
      }

      100% {
        bottom: -2px;
      }
    }

    @keyframes starBottom {
      0% {
        bottom: -3px;
      }

      10% {
        bottom: -4px;
      }

      20% {
        bottom: -5px;
      }

      30% {
        bottom: -6px;
      }

      40% {
        bottom: -7px;
      }

      50% {
        bottom: -7px;
      }

      60% {
        bottom: -6px;
      }

      70% {
        bottom: -5px;
      }

      80% {
        bottom: -4px;
      }

      90% {
        bottom: -3px;
      }

      100% {
        bottom: -2px;
      }
    }
  }
}
</style>
