export default {
  data() {
    return {
      userProgress: {},
      showPage: null,
      showPageNumber: null,
    }
  },
  watch: {
    registerStep(number) {
      this.userProgress = this.progressList.find(f => f.id == number)
    },
    showPageNumber() {
      this.$nextTick(() => {
        const elMain = document.getElementById('elMain')
        elMain.scrollTop = 0
      })
    },
  },
  beforeMount() {
    this.showPage = this.userProgress = this.progressList.find(f => f.id == this.registerStep)
    this.showPageNumber = this.registerStep
    if (!this.disableField && this.registerStep >= 5) this.$store.commit('register/setDisableField', true)
  },
  methods: {
    skipNextStep() {
      if (this.showPageNumber != 4) return
      this.showPageNumber += 2
      this.showPage = this.progressList.find(f => f.id == this.showPageNumber)
      if (this.registerStep < this.showPageNumber) {
        this.$store.commit('common/setRegisterStep', this.showPageNumber)
        if (this.showPageNumber >= 5) this.$store.commit('register/setDisableField', true)
      }
      // this.$router.push('/registerFinish')
    },
    addPage() {
      this.showPageNumber++
      this.showPage = this.progressList.find(f => f.id == this.showPageNumber)
      if (this.registerStep < this.showPageNumber) {
        this.$store.commit('common/setRegisterStep', this.showPageNumber)
        if (this.showPageNumber == 5) this.$store.commit('register/setDisableField', true)
      }
    },
    reducePage() {
      if (this.showPageNumber > 1) {
        this.showPageNumber--
        this.showPage = this.progressList.find(f => f.id == this.showPageNumber)
      }
    },
    submit() {
      this.$refs.page.submit()
    },
    openConfirmIdDrawer() {
      this.$refs.confirmIdInfo.openDrawder()
    }
  },
  computed: {
    registerStep() {
      return this.$store.state.common.registerStep
    },
    disableField() {
      return this.$store.state.register.disableField
    },
  },
}
